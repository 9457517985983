/* You can add global styles to this file, and also import other style files */
@import 'all.css';
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';

:root {
  --ra-theme-bg-color: #05053A;
  --ra-theme-color: #FFFFFF;
  --ra-theme-accent-color: #0B3EF9;
  --ra-theme-active-menu-bg-color: #9999AC;
  --ra-theme-selected-item-bg-color: #71E2AF;
  --ra-theme-text-color: #333333;
  --ra-grid-header-bg-color: #E6E7E8;
  --ra-grid-clickable-col-bg-color: #E6E7E8;
  --ra-card-title-color: #05053A;
  --ra-page-bg-color: #FFFFFF;
  --ra-theme-secondary-bg-color: #F1F3FC;
  --ra-page-title-color: #05053A;
  --ra-metric-card-value-color: #0B3EF9;
  --ra-metric-card-neg-color: #EF7E9A;
  --ra-metric-card-pos-color: #0AC570;
  --ra-metric-card-label-color: #B8B8B8;

  // Inventory Override Related Vars
  --rec-profile-bg-color: #0B3EF9;
  --profile-color: #FFFFFF;
  --grid-protected-row-color: #92CAFF;
  --grid-invalid-row-color: #FC6F6080;
  --grid-closed-row-color: #C7C7C7;
}

::selection {
  color: var(--ra-theme-color);
  background-color: var(--ra-theme-bg-color);
}

.ra-fieldset-card {
  margin: 0 1em 1em;
  padding: 1em;
}

.grid-toolbar {
  margin-bottom: 0.5em;

  label {
    margin-right: 0.5em;
  }

  button {
    margin-right: 0.5em;
    color: var(--ra-theme-text-color);
  }

  .multi-select {
    margin-right: 0.5em;
    max-width: 35%;
  }
}

.high-color {
  color: limegreen;
}

.medium-color {
  color: orange;
}

.low-color {
  color: red;
}

.k-grid {
  font-size: 12px;
}

.k-grid th {
  padding: 2px 6px;
}

.k-grid td {
  padding: 2px 4px;
  color: var(--ra-theme-text-color);
}

.spinner-wrapper {
  position: relative;
  height: 100%;
}

.k-i-loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 64px;
  background-color: rgba(255, 255, 255, 0.3);
  color: var(--ra-theme-bg-color);
}

// Styling for Datapicker parts
.k-list .k-item.k-state-selected,
.k-list .k-item.k-state-selected:hover {
  background-color: var(--ra-theme-selected-item-bg-color);
  color: var(--ra-theme-text-color);
}

.k-calendar .k-footer .k-nav-today,
.k-calendar .k-calendar-header .k-today {
  color: var(--ra-theme-text-color);
}

.k-calendar .k-state-selected .k-link {
  color: var(--ra-theme-bg-color);
  background-color: var(--ra-theme-selected-item-bg-color);
}

.k-calendar .k-state-selected.k-state-focused .k-link {
  color: var(--ra-theme-bg-color);
  background-color: var(--ra-theme-selected-item-bg-color);
}

.k-calendar .k-content .k-today {
  color: var(--ra-theme-text-color);
}

.k-textbox::selection,
.k-input::selection,
.k-textarea::selection {
  color: var(--ra-theme-color);
  background-color: var(--ra-theme-bg-color);
}

.k-dialog-titlebar {
  color: var(--ra-theme-color);
  background-color: var(--ra-theme-bg-color);
}

.k-grid td.k-state-selected,
.k-grid tr.k-state-selected > td {
  color: var(--ra-theme-text-color);
  background-color: var(--ra-theme-selected-item-bg-color);
}

.k-checkbox:checked + .k-checkbox-label::before {
  border-color: var(--ra-theme-bg-color);
  background-color: var(--ra-theme-bg-color);
}

.k-widget ::selection,
.k-block ::selection,
.k-panel ::selection {
  color: var(--ra-theme-color);
  background-color: var(--ra-theme-bg-color);
}

.k-switch-on:hover .k-switch-container,
.k-switch-on.k-state-hover .k-switch-container {
  background-color: var(--ra-theme-bg-color);
}

.k-widget ::selection,
.k-block ::selection,
.k-panel ::selection {
  color: var(--ra-theme-color);
  background-color: var(--ra-theme-bg-color);
}

.k-switch-on .k-switch-container {
  background-color: var(--ra-theme-bg-color);
}

.drop-down-popup {
  font-size: 10pt;
}

.ra-grid-header {
  background-color: var(--ra-grid-header-bg-color);
  color: var(--ra-theme-text-color);
  opacity: 0.7;
  padding: 8px 4px 8px 4px !important;

  label.k-checkbox-label::before {
    border-color: var(--ra-theme-text-color) !important;
  }

  a:hover, span:hover {
    color: #a9a9a9 !important;
  }
}

.ra-grid-no-header {
  height: 0;
  border-bottom-width: 0;
  display: none;
  overflow: hidden;
}

.ra-grid-checkbox {
  text-align: center !important;
}

.ra-dialog-button-group {
  border-width: 1px 0;
  border-color: #00000014;

  .ra-dialog-button {
  }
}

.login-container {
  position: fixed;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;

  .login-text {
    text-align: center;
    width: 100%;
  }

  .loading-icon {
    padding: 10px;
    color: var(--ra-theme-bg-color);
  }

  a {
    color: #007bff;
    cursor: pointer;
  }
}

.k-grid-header .k-i-sort-asc-sm,
.k-grid-header .k-i-sort-desc-sm,
.k-grid-header .k-sort-order {
  color: var(--ra-theme-text-color);
}

.ra-toggle-column {
  display: contents;
  width: 100%;
}

tr .col-pull-right {
  text-align: right;
}

tr .col-pull-center {
  text-align: center;
}

tr .col-clickable {
  background-color: var(--ra-grid-clickable-col-bg-color);
  cursor: pointer;
}

tr .override-grid-input {
  input {
    font-size: 12px !important; // needed to override default kendo value
  }
}

.ra-grid-footer {
  background-color: var(--ra-grid-header-bg-color);
}

mat-card {
  box-shadow: 0 2px 10px rgba(106, 106, 106, .25) !important; // #6A6A6A
  border-color: rgba(205, 205, 205, .5); // #DADADA
  border-style: solid;
  border-width: 2px;
}

.ra-card {
  .card-title {
    font-family: "Sofia Pro Light", sans-serif;
    font-size: 24px;
    color: var(--ra-card-title-color);
  }
}

.chart-card-header {
    .mat-card-header-text {
      width: 75%;
      margin: 0;
    }
}

.chart-card-content {
    height: 90%;
    margin-top: 0;
}

.tooltip-container {
  font-size: 11px;
  width: 200px;


  .tooltip-row {
    display: flex;

    .tooltip-label {
      font-weight: bold;
      width: 70%;
    }

    .tooltip-label-ra {
      width: 50%;
    }

    .tooltip-value {
      width: 30%;
      text-align: right;
      color: #8e8e8e;
    }

    .tooltip-value-ra {
      width: 40%;
    }
  }
}

.mat-ink-bar {
  background-color: var(--ra-theme-selected-item-bg-color) !important;
}

.mat-tab-body-wrapper {
  flex-grow: 1;
}

.mat-tab-body-content {
  .ng-star-inserted {
    height: 100%;
  }
}

// Styling needed for tube chart. Had to put here because it wasn't being applied in the
// inventory.component.scss
.tube-chart-tab-group> .mat-tab-body-wrapper {
  mat-tab-body > .mat-tab-body-content {
    overflow: hidden;
  }
}

li.k-item.k-menu-item.user-menu-item {
  background-color: var(--ra-theme-bg-color) !important;
  color: var(--ra-theme-color) !important;
}

.wide-chart-tooltip-settings {
  margin-left: -300px !important;
  opacity: 0.8;
}

.default-chart-tooltip-settings {
  margin-left: -200px !important;
  opacity: 0.8;
}

.dmd-fcst-chart-tooltip {
  margin-left: -245px !important;
  opacity: 0.8;
  width: 240px;
}

.chart-tooltip-category {
  text-align: center;
  font-weight: bold;
}

.chart-tooltip-container {
  display: grid;
  grid-template-columns: 20px repeat(2, 1fr);
  grid-auto-rows: auto;
  grid-row-gap: 5px;
  grid-column-gap: 5px;
}

.chart-tooltip-point-color {
  display: flex;
  align-items: center;
}

.chart-tooltip-series-name {
  font-weight: bold;
}

.chart-tooltip-point-value {
  text-align: right;
}

.k-notification-group {
  z-index: 100;
}
