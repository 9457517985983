@font-face {
  font-family: 'Sofia Pro';
  src: url('../fonts/Mostardesign - Sofia Pro Regular.otf');
  font-weight: normal;
}
@font-face {
  font-family: 'Sofia Pro Light';
  src: url('../fonts/Mostardesign - Sofia Pro Light.otf');
}
@font-face {
  font-family: 'Sofia Pro Medium';
  src: url('../fonts/Mostardesign - Sofia Pro Medium.otf');
}
@font-face {
  font-family: 'Sofia Pro Semi Bold';
  src: url('../fonts/Mostardesign - Sofia Pro Semi Bold.otf');
}

* {
  font-family: 'Sofia Pro', 'Century Gothic', 'Futura', sans-serif;
}